import { atom } from "recoil";

export const tvdState = atom({
  key: "tvdState",
  default: 0,
});

export const uniLpLockerState = atom({
  key: "uniLpLockerState",
  default: 0,
});
