import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { useRecoilState } from 'recoil';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
//components
// import Image from "./List/image.js";
import Row from './List/row.js';
/* Libraries */
import {
  getChargerList,
  createContractInstance,
  // getTokenInfo,
  getChargerInfo,
} from '../../../lib/read_contract/Station';
import { fromWei, toBN } from 'web3-utils';
/* Store */
import { web3ReaderState } from '../../../store/read-web3';
import {
  getAllContracts,
  getAssaplayUsd,
  getRcgUsd,
  getPenUsd,
  getKusUsd,
  getwKcsUsd,
  getUsdtUsd,
  getMapUsd,
  getCchgUsd
} from '../../../api/contract'
import moment from 'moment';
import { bscAbi, kusLPAbi, pancakeAbi } from '../../../constants'
import Web3 from 'web3';
import _ from 'underscore';
import { poolContractListAtom } from '../../../store/pool';
// import { ReactComponent as DropdownClose } from "./List/assets/dropdown-close.svg";
// import { ReactComponent as DropdownOpen } from "./List/assets/dropdown-open.svg";

Array.prototype.insert = function (index, item) {
  this.splice(index, 0, item);
};

const loading_data = [
  {
    address: '0x0',
    // apy: "-",
    name: 'Loading List..',
    period: [1625022000, 14400],
    redemtion: 200,
    symbol: ['RCG', 'RCG'],
    token: [
      '0xe74be071f3b62f6a4ac23ca68e5e2a39797a3c30', // 이더리움 토큰주소
      '0xe74be071f3b62f6a4ac23ca68e5e2a39797a3c30',
    ],
    tvl: '-',
    type: 'flexible',
    network: 'ERC',
  },
];
const chargerInfo = [
  {
    address: '0x0',
    // apy: "-",
    name: 'There is currently no Charger List available.',
    period: [1625022000, 14400],
    redemtion: 200,
    symbol: ['RCG', 'RCG'],
    token: [
      '0xe74be071f3b62f6a4ac23ca68e5e2a39797a3c30', // 이더리움 토큰주소
      '0xe74be071f3b62f6a4ac23ca68e5e2a39797a3c30',
    ],
    tvl: '-',
    type: 'flexible',
    network: 'ERC',
  },
];
//
function List({ /*type, list,*/ params, toast, network, setTvl }) {
  const [t] = useTranslation();
  const [fullList, setFullList] = useState(loading_data);
  const [chList, setChList] = useState(loading_data);

  const [bscPrice, setBscPrice] = useState(null);
  const [allContractInfo, setAllContractInfo] = useState([]);
  const [poolContractList, setPoolContractList] = useRecoilState(poolContractListAtom);

  const setIsOpenValue = (address, contractLists) => {
    const filterData = contractLists.filter((contract) => {
      return contract.address === address;
    });

    return filterData[0]?.isOpen;
  };

  // const [isOpen, setOpen] = useState(false);
  const [web3_R] = useRecoilState(web3ReaderState);
  const NETWORKS = require('../../../lib/networks.json');

  const getSeq = (address, contractLists) => {
    const filteredData = contractLists.filter((item) => {
      return item.address === address;
    });
    return filteredData[0]?.seq;
  };

  const getKusLpBalances = async (cAddress, symbol1, symbol2) => {
    const web3 = new Web3('https://rpc-mainnet.kcc.network');
    const contract = new web3.eth.Contract(kusLPAbi, cAddress);
    const data = await contract.methods.getReserves().call();
    return {
      'total': await contract.methods.totalSupply().call(),
      [symbol1]: data['0'],
      [symbol2]: data['1']
    }
  }

  const loadChargerList = async () => {
    // const priceData = await axios.post(`https://api.thegraph.com/subgraphs/name/uniswap/uniswap-v2`, {
    //   query: 'query{pairs(where:{id:"0x9c20be0f142fb34f10e33338026fb1dd9e308da3"}) { token0Price token1Price }}',
    // });
    // const RCG_PRICE = makeNum(priceData.data.data.pairs[0].token0Price);
    const RCG_PRICE = await getRcgUsd();

    // kuslp
    const lpBalances = await getKusLpBalances('0x1ee6b0f7302b3c48c5fa89cd0a066309d9ac3584', 'wKCS', 'KUS')
    const lpBalances2 = await getKusLpBalances('0xF531EE5cb4Fd655522122d7036389474B7CEc677', 'USDT', 'RCG')

    /**
     * 1. 모든 차져리스트를 받는다
     *  1-1. 각 네트워크에 대한 web3, 풀어드레스를 받는다
     *  1-2. 각 네트워크에 대한 차져 리스트를 받을 수 있다.
     *
     * {
     *    ERC: [],
     *    BEP: [],
     *    HRC: [],
     * }
     *
     * 2. 모든 차져리스트에 대한 인스턴스 생성
     * 3. 모든 차져 인스턴스에 대한 인포 받기
     * 4. 네트워크, 타입에 따라 필터링 진행
     */

    const realignArray = (insertIdx, deleteIdx, arr) => {
      const insertData = arr[deleteIdx];
      arr.splice(deleteIdx, 1);
      arr.insert(1, insertData);
      return arr;
    };

    const ETH_WEB3 = web3_R.ERC;
    const BEP_WEB3 = web3_R.BEP;
    const HRC_WEB3 = web3_R.HRC;
    const KCC_WEB3 = web3_R.KCC;
    const ALL_WEB3 = [ETH_WEB3, BEP_WEB3, HRC_WEB3, KCC_WEB3];

    const NETWORK = NETWORKS['mainnet'];
    // const TOKEN_ADDRESS = NETWORK.tokenAddress[network]; //
    const ETH_CHARGERLIST_ADDRESS = NETWORK.chargerListAddress.ERC;
    const BEP_CHARGERLIST_ADDRESS = NETWORK.chargerListAddress.BEP;
    const HRC_CHARGERLIST_ADDRESS = NETWORK.chargerListAddress.HRC; //
    const KCC_CHARGERLIST_ADDRESS = NETWORK.chargerListAddress.KCC;

    const CHARGERLIST_ABI = require('../../../lib/read_contract/abi/chargerList.json');
    const TOKEN_ABI = require('../../../lib/read_contract/abi/erc20.json');
    const CHARGER_ABI = require('../../../lib/read_contract/abi/charger.json');

    const ETH_CHARGERLIST_INSTANCE = createContractInstance(ETH_WEB3, ETH_CHARGERLIST_ADDRESS, CHARGERLIST_ABI);
    const BEP_CHARGERLIST_INSTANCE = createContractInstance(BEP_WEB3, BEP_CHARGERLIST_ADDRESS, CHARGERLIST_ABI);
    const HRC_CHARGERLIST_INSTANCE = createContractInstance(HRC_WEB3, HRC_CHARGERLIST_ADDRESS, CHARGERLIST_ABI); //

    const getList = async () => {
      const allContract = await getAllContracts();
      const _allContract = _.flatten(
        Object.keys(allContract.chargeList).map((key, i) => {
          return allContract.chargeList[key];
        })
      );

      const privateLocker = _.flatten(
        Object.keys(allContract.privateLocker).map((key, i) => {
          return allContract.privateLocker[key];
        })
      );

      const sumContract = _allContract.concat(privateLocker);
      sumContract.forEach((contract) => {
        contract.isOpen = false;
      });
      _allContract.forEach((contract) => {
        contract.isOpen = false;
      });
      setPoolContractList(sumContract);

      setAllContractInfo(_allContract);

      const ETH_CHARGER_LIST = allContract.chargeList.ETH.map((item) => item.address);
      const BEP_CHARGER_LIST = allContract.chargeList.BSC.map((item) => item.address);
      const HRC_CHARGER_LIST = allContract.chargeList.HECO.map((item) => item.address);
      const KCC_CHARGER_LIST = allContract.chargeList.KCC.map((item) => item.address);

      // const ETH_CHARGER_LIST = await getChargerList(ETH_CHARGERLIST_INSTANCE);
      // const BEP_CHARGER_LIST_2 = await getChargerList(BEP_CHARGERLIST_INSTANCE);
      // const HRC_CHARGER_LIST = await getChargerList(HRC_CHARGERLIST_INSTANCE); //

      const ALL_NETWORK_CHARGERLIST = [ETH_CHARGER_LIST, BEP_CHARGER_LIST, HRC_CHARGER_LIST, KCC_CHARGER_LIST];

      if (ETH_CHARGER_LIST.length === 0 && BEP_CHARGER_LIST.length === 0) return setChList(chargerInfo);

      let ALL_RESULTS = {
        0: [],
        1: [],
        2: [], //
        3: [],
      };

      const ALL_CHARGER_INSTANCES = ALL_NETWORK_CHARGERLIST.map((CHARGERLIST, network) => {
        return CHARGERLIST.map((CHARGER_ADDRESS) =>
          createContractInstance(ALL_WEB3[network], CHARGER_ADDRESS, CHARGER_ABI)
        );
      });

      const ALL_CHARGERS_INFO = await Promise.all(
        ALL_CHARGER_INSTANCES.map(async (CHARGER_INSTANCES) => {
          return Promise.all(CHARGER_INSTANCES.map((INSTANCE) => getChargerInfo(INSTANCE)));
        })
      );

      ALL_CHARGERS_INFO.map((CHARGERS_INFO, network) => {
        CHARGERS_INFO.map((INFO, i) => {
          ALL_RESULTS[network][i] = INFO;
        });
      });

      const ALL_REWARDS_AMOUNT = await Promise.all(
        ALL_NETWORK_CHARGERLIST.map(async (CHARGERLIST, network) => {
          return Promise.all(
            CHARGERLIST.map((CHARGER_ADDRESS, i) => {
              const REWARDTOKEN_INSTANCE = createContractInstance(
                ALL_WEB3[network],
                ALL_RESULTS[network][i].rewardToken,
                TOKEN_ABI
              );
              return REWARDTOKEN_INSTANCE.methods.balanceOf(CHARGER_ADDRESS).call();
            })
          );
        })
      );
      const ALL_REWARDS_SYMBOL = await Promise.all(
        ALL_NETWORK_CHARGERLIST.map((CHARGERLIST, network) => {
          return Promise.all(
            CHARGERLIST.map((CHARGER_ADDRESS, i) => {
              const TOKEN_INSTANCE = createContractInstance(
                ALL_WEB3[network],
                ALL_RESULTS[network][i].rewardToken,
                TOKEN_ABI
              );
              return TOKEN_INSTANCE.methods.symbol().call();
            })
          );
        })
      );

      const ALL_STAKES_SYMBOL = await Promise.all(
        ALL_NETWORK_CHARGERLIST.map((CHARERLIST, network) => {
          return Promise.all(
            CHARERLIST.map((CHARGER_ADDRESS, i) => {
              const TOKEN_INSTANCE = createContractInstance(
                ALL_WEB3[network],
                ALL_RESULTS[network][i].stakeToken,
                TOKEN_ABI
              );
              return TOKEN_INSTANCE.methods.symbol().call();
            })
          );
        })
      );
      const ALL_STAKES_BASEPERCENT = await Promise.all(
        ALL_NETWORK_CHARGERLIST.map((CHARGERLIST, network) => {
          return Promise.all(
            CHARGERLIST.map(async (CHARGER_ADDRESS, i) => {
              if (ALL_STAKES_SYMBOL[network][i] != 'RCG') return 0;
              const TOKEN_INSTANCE = createContractInstance(
                ALL_WEB3[network],
                ALL_RESULTS[network][i].stakeToken,
                TOKEN_ABI
              );
              return TOKEN_INSTANCE.methods.basePercent().call();
            })
          );
        })
      );

      const usd = {
        'RCG': await getRcgUsd(),
        'PEN': await getPenUsd(),
        'ASSA': await getAssaplayUsd(),
        'KUS': await getKusUsd(),
        'wKCS': await getwKcsUsd(),
        'USDT': await getUsdtUsd(),
        'MAP': await getMapUsd(),
        'CCHG': await getCchgUsd()
      }

      await ALL_NETWORK_CHARGERLIST.map(async (CHARGERLIST, network) => {
        let net;
        switch (network) {
          case 0:
            net = 'ERC';
            break;
          case 1:
            net = 'BEP';
            break;
          case 2:
            net = 'HRC';
            break;
          case 3:
            net = 'KCC';
            break;
        }

        await CHARGERLIST.map((CHARGER_ADDRESS, i) => {
          ALL_RESULTS[network][i].address = CHARGER_ADDRESS;
          ALL_RESULTS[network][i].status = loadActiveStatus(ALL_RESULTS[network][i]);
          // 컨트랙트에서 미니멈 값을 제대로 주기 전까지 일시적으로 사용합니다.
          ALL_RESULTS[network][i].minimum = fromWei(ALL_RESULTS[network][i].limit, 'ether');
          ALL_RESULTS[network][i].seq = getSeq(CHARGER_ADDRESS, _allContract);
          ALL_RESULTS[network][i].rewardAmount = ALL_REWARDS_AMOUNT[network][i];
          ALL_RESULTS[network][i].basePercent = ALL_STAKES_BASEPERCENT[network][i];
          ALL_RESULTS[network][i].symbol = [ALL_REWARDS_SYMBOL[network][i], ALL_STAKES_SYMBOL[network][i]];

          let stakeCoingekoUsd = usd[ALL_STAKES_SYMBOL[network][i]] || usd['RCG'];
          let rewardCoingekoUsd = usd[ALL_REWARDS_SYMBOL[network][i]] || usd['RCG'];

          // 2.1 wKCS-KUS LP 예외처리
          if (ALL_RESULTS[network][i].name.includes('2.1 wKCS - KUS LP Flexible')) {
            stakeCoingekoUsd = (
              usd['wKCS'] * lpBalances['wKCS']
              +
              usd['KUS'] * lpBalances['KUS']
            ) / lpBalances['total'];
          }

          if (ALL_RESULTS[network][i].name.includes('2.8 RCG - USDT LP Locked')) {
            stakeCoingekoUsd = (
              usd['RCG'] * lpBalances2['RCG']
              +
              usd['USDT'] * lpBalances2['USDT']
            ) / lpBalances2['total'];
          }

          ALL_RESULTS[network][i].network = net;
          ALL_RESULTS[network][i].apy = renewalGetAPY(
            ALL_RESULTS[network][i],
            allContract,
            stakeCoingekoUsd,
            rewardCoingekoUsd
          );
          ALL_RESULTS[network][i].isLP = ALL_RESULTS[network][i].name.includes('LP');
          ALL_RESULTS[network][i].isLocked = ALL_RESULTS[network][i].name.includes('Locked');
          ALL_RESULTS[network][i].poolTVL = renewalTVL(
            ALL_RESULTS[network][i].totalSupply,
            stakeCoingekoUsd
          );
        });
      });

      let ALL_LIST = [];
      for (let network in ALL_RESULTS) {
        ALL_RESULTS[network].map((charger) => {
          if (charger.name === '9.3 Locked Pool 500' || charger.name === '9.15 BSC Zero-Burning Pool 20') {
          } else {
            ALL_LIST.push(charger);
          }
        });
      }

      let tvl = 0;
      ALL_LIST.map((charger) => (tvl += Number(fromWei(charger.totalSupply, 'ether'))));

      ALL_LIST = ALL_LIST.sort((p, n) => {
        return p.seq - n.seq;
      });

      setTvl(tvl * RCG_PRICE);

      if (ALL_LIST.length === 0) {
        setChList(chargerInfo);
        setFullList(chargerInfo);
      } else {
        setChList(ALL_LIST);
        setFullList(ALL_LIST);
      }
    };
    getList();

    return;
  };

  const filterByNetwork = (chargerList) => {
    return chargerList.filter((charger) => charger.network === network);
  };
  const filterByType = (chargerList) => {
    if (params.isLP) {
      return chargerList.filter((charger) => charger.name.includes(params.type) && charger.name.includes('LP'));
    } else {
      return chargerList.filter((charger) => charger.name.includes(params.type) && !charger.name.includes('LP'));
    }
  };

  const renewalTVL = (totalSupply, coingecko) => {
    const total = parseInt(fromWei(totalSupply, 'ether'), 10);
    return total * coingecko.toLocaleString();
  };

  // Whenever Staking type is changed, reload Pool list
  useEffect(async () => {
    setChList(loading_data);
    try {
      await loadChargerList();
    } catch (err) {
      console.log(err, 'error');
    }
  }, []);
  useEffect(async () => {
    try {
      let list;
      if (network === 'ALL' && params.type === 'ALL') {
        setChList(fullList);
      } else if (network !== 'ALL' && params.type === 'ALL') {
        list = await filterByNetwork(fullList);
      } else if (network === 'ALL' && params.type !== 'ALL') {
        list = await filterByType(fullList);
      } else {
        list = await filterByNetwork(fullList);
        list = await filterByType(list);
      }
      if (list.length === 0) {
        setChList(chargerInfo);
      } else {
        setChList(list);
      }
    } catch (err) {
      console.log(err);
    }
  }, [params, network]);

  const renewalGetAPY = (
    item,
    allContract,
    stakeCoingekoUsd,
    rewardCoingekoUsd
  ) => {
    const endTime = Number(item.startTime) + Number(item.DURATION);
    const currentTime = Number(moment(new Date()).unix());
    const duration = endTime - currentTime;
    const nums = 0.000000000000000001;

    const year = 365 * 24 * 60 * 60;
    const _allContract = _.flatten(
      Object.keys(allContract.chargeList).map((key, i) => {
        return allContract.chargeList[key];
      })
    );
    const filteredData = _allContract.filter((list, i) => {
      return item.name === list.name;
    });
    const totalRewardUsd = filteredData[0].liquidity * rewardCoingekoUsd;
    const totalSupply = Number(fromWei(item.totalSupply));

    const totalDepositUsd = totalSupply * stakeCoingekoUsd;

    const result = (year / Number(item.DURATION)) * (totalRewardUsd / totalDepositUsd) * 100;
    return result;
  };

  return (
    <Container>
      <Content>
        <RowContainer>
          {chList.map((charger, index) => {
            return (
              <div
                // className={params.isLP === true ? "disable" : ""}
                style={charger.name === 'Loading List..' ? { cursor: 'not-allowed' } : {}}
              >
                <div style={{ cursor: 'pointer' }}>
                  <Row
                    key={charger.name}
                    index={index}
                    status={charger.status} // active or not
                    name={charger.name}
                    tvl={charger.totalSupply}
                    apy={charger.apy}
                    info={charger}
                    limit={charger.limit}
                    params={params} // 버튼에 대한 분기처리 때문에 필요
                    toast={toast}
                    period={loadPoolPeriod(charger.startTime, charger.DURATION)}
                    poolNet={charger.network}
                    startTime={charger.startTime}
                    poolTVL={charger.poolTVL}
                    allContractInfo={allContractInfo}
                    isOpen={setIsOpenValue(charger.address, poolContractList)}
                  />
                </div>
              </div>
            );
          })}
        </RowContainer>
      </Content>
    </Container>
  );
}

const loadPoolPeriod = (startTime, duration) => {
  let ret = '21.01.01 00:00:00 ~ 21.01.30 00:00:00((UTC+9)+9)';
  const endTime = Number(startTime) + Number(duration);

  const formatter = (timestamp) => {
    var d = new Date(Number(timestamp) * 1000);
    const z = (x) => {
      return x.toString().padStart(2, '0');
    };
    return `${new String(d.getFullYear()).substr(2, 3)}.${z(d.getMonth() + 1)}.${z(d.getDate())} ${z(d.getHours())}:${z(
      d.getMinutes()
    )}:${z(d.getSeconds())}`;
  };
  ret = `${formatter(startTime)} ~ ${formatter(endTime)}`;
  return ret;
};

const loadActiveStatus = ({ totalSupply, startTime, DURATION, limit, name }) => {
  startTime = Number(startTime);
  DURATION = Number(DURATION);
  let NOW = new Date().getTime() / 1000;
  // 11.1 풀을 위해 일시적으로 사용합니다.
  // if (name.includes('11.1 ')) return 'Inactive';

  if (name.includes('10.1 ')) return 'Inactive';

  if (name.includes('11.12 ')) return 'Closed';

  if (NOW < startTime) return 'Inactive';

  if (NOW > startTime + DURATION) return 'Closed';
  // 리미트 설정 이전까지 잠정 주석처리 합니다.
  // if (limit != "0" && totalSupply >= limit) return "Close";
  return 'Active';
};
function makeNum(str = '0', decimal = 4) {
  let newStr = str;
  if (typeof newStr === 'number') newStr = str.toString();
  let arr = newStr.split('.');
  if (arr.length == 1 || arr[0].length > 8) return arr[0];
  else {
    return arr[0] + '.' + arr[1].substr(0, decimal);
  }
}

function convert(n) {
  var sign = +n < 0 ? '-' : '',
    toStr = n.toString();
  if (!/e/i.test(toStr)) {
    return n;
  }
  var [lead, decimal, pow] = n
    .toString()
    .replace(/^-/, '')
    .replace(/^([0-9]+)(e.*)/, '$1.$2')
    .split(/e|\./);
  return +pow < 0
    ? sign + '0.' + '0'.repeat(Math.max(Math.abs(pow) - 1 || 0, 0)) + lead + decimal
    : sign +
        lead +
        (+pow >= decimal.length
          ? decimal + '0'.repeat(Math.max(+pow - decimal.length || 0, 0))
          : decimal.slice(0, +pow) + '.' + decimal.slice(+pow));
}

const Container = styled.div`
  // margin-top: 20px;
  margin-bottom: 120px;
  display: flex;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 1088px;

  img {
    height: 60px;
    margin: auto;
    @media (min-width: 1088px) {
      margin: 0;
      align-items: flex-end
    }
  }
  div{
  }
  p {
    color: white;
    // margin: auto;
    margin-top: 20px;

    @media (min-width: 1088px) {
      margin: 0;
      margin-left: 10px;
      margin-bottom: 0px;
  }
`;

const Title = styled.div`
  display: flex;
  flex-direction: column;
  @media (min-width: 1088px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-end;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;
const RowContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 20px 30px 0px 30px;

  @media (min-width: 1088px) {
    margin: 0px;
    margin-top: 40px;
  }

  .disable {
    cursor: not-allowed;
    opacity: 0.5;
  }
`;

const TotalValue = styled.div``;
const Line = styled.div`
  height: 2px;
  margin: 40px 10px 0px 10px;
  width: auto;
  background-color: #9314b2;
  box-shadow: 0px 0px 20px 0.5px white;
`;

// const DropDownWrapper = styled.div`
// display:flex;
// justify-content: flex-end;
// `;
// const NetWork = styled.div``;
// const Text = styled.div``;
// const Box = styled.div`
// width: 196px;
// height: 42px;
// // margin: 8px 0 0 302px;
// // padding: 0 0 0 2px;
// object-fit: contain;
// border-radius: 30px;
// background-color: var(--black-30);
// `;
// const Type = styled.div``;
// const Sortby = styled.div``;
// const BoxContainer = styled.div`
// display:flex;
// `;
// const Button = styled.div``;
// const Btn = styled.div``

export default List;
